import React from "react"
import Layout from "../../../Components/pageComponents/contentContainer"
import SolutionInfoSection from "../../../Components/pageComponents/solutionsInfoSection"


export const query = graphql`
    query {
        banner: file(relativePath: { eq: "products/networking/routers-and-switches/Routers _ Switches Banner.png" }) {
            childImageSharp {
                fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        stock: file(relativePath: { eq: "products/networking/routers-and-switches/Routers and Switches Stock.png" }) {
            childImageSharp {
                fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

export default ({ data }) => {
    return (
        <Layout title="Routers & Switches" image={data.banner.childImageSharp.fluid}>
            <SolutionInfoSection heading="Products We Offer" title="Routers and Switches" description=" Our strategy is to utilize a layered approach or structure consistent with the Open System Interconnection (OSI) model. We listen to our customer requirements and design solutions that meet their specific needs providing a vast array of Core Routers, Distribution Switches, Edge Switches" img={data.stock.childImageSharp.fluid} />
            {/* <BackgroundImage fluid={data.stock.childImageSharp.fluid}>
                <h3 style={{ margin: 0, padding: 0 }}>
                    <div class="py-5">
                        <div
                            class="container-md shadow-lg px-5 py-5"
                            style={{
                                color: "#F3F3F3",
                                backgroundColor: "#143469EE"
                            }}
                        >
                            Our strategy is to utilize a layered approach or structure consistent with the Open System Interconnection (OSI) model. We listen to our customer requirements and design solutions that meet their specific needs providing a vast array of
                            <ul class="mt-3">
                                <li>Core Routers</li>
                                <li>Distribution Switches</li>
                                <li>Edge Switches</li>
                            </ul>
                        </div>
                    </div>
                </h3>
            </BackgroundImage> */}
        </Layout>
    )
}